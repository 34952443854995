.main-container {
    align-items: center;
    padding: 100px 50px;
}

.main-container p {
    font-size: large;
}

.main-container p span {
    font-weight: bold;
}
