.reg-context-container {
    margin-top: 40px;
    text-align: center;
}

.reg-context {
    color: #666;
}

.custom-date-picker .MuiFormLabel-root.MuiInputLabel-root.Mui-error {
    color: initial;
}

.custom-date-picker .MuiInputBase-root.Mui-error:after {
    border-bottom-color: transparent;
}

.custom-date-picker .MuiInputBase-root.MuiInput-root.Mui-error::before {
    border-bottom-color: initial;
}