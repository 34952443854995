.stack-container {
    align-items: center;
}

.input-container {
    position: relative;
    width: 100%;
}

.error-container {
    position: absolute;
    top: 10px;
    right: 0px;
    border: 1px solid #c80000;
    width: fit-content;
    padding: 4px 10px;
    display: flex;
}

.error-container>p {
    margin-right: 5px;
    color: #c80000;
    font-size: small;
}

.context-container {
    margin-top: 120px;
    text-align: center;
}

.context {
    color: #666;
    text-align: "center";
}

.link {
    text-decoration: none;
    color: black;
}

.link:hover {
    color: blue;
}